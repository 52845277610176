import { FC, useEffect, useRef, useState } from 'react';
import { ErrorAlert } from '../components/Alerts';
import { InteractiveBackground } from '../components/InteractiveBackground';
import { useClientAuthStore } from '../store/clientAuth';
import { SignInContainer } from '../components/SignInContainer';

export const ClientSignIn: FC = () => {
	const { state, signIn } = useClientAuthStore();

	const passwordRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	useEffect(() => {
		emailRef?.current?.focus();
	}, []);

	return (
		<InteractiveBackground
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<SignInContainer
				onSubmit={e => {
					e.preventDefault();
					signIn(email, password);
				}}
			>
				<h2>Sign in</h2>
				<input
					placeholder="E-mail"
					type="text"
					value={email}
					ref={emailRef}
					onChange={event => setEmail(event.target.value)}
					onKeyPress={e => {
						if (e.key === 'enter') {
							if (!password) {
								passwordRef.current?.focus();
							} else {
								signIn(email, password);
							}
						}
					}}
					autoComplete="username"
				/>
				<input
					placeholder="Password"
					type="password"
					value={password}
					ref={passwordRef}
					onChange={event => setPassword(event.target.value)}
					autoComplete="current-password"
				/>
				{state.type === 'error' && <ErrorAlert>{state.error}</ErrorAlert>}
				<button disabled={!email || !password}>Sign in</button>
			</SignInContainer>
		</InteractiveBackground>
	);
};
