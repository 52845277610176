import { FC, useEffect } from 'react';
import QRCodeGenerator from 'react-qr-code';
import useMeasure from 'react-use-measure';
import styled, { useTheme } from 'styled-components';
import { PorterbuddyLogo } from '../components/PorterbuddyLogo';
import { TokenExpiresIn } from '../components/TokenExpiresIn';
import { useGetQrToken } from '../network/usePunchClient';
import { settings } from '../settings';
import { useTerminalVerificationTokenStore } from '../store/verificationToken';

export const QRCode: FC = () => {
	const { token, deleteToken } = useTerminalVerificationTokenStore();
	const { data, isLoading, isError } = useGetQrToken(token ?? '');
	const [ref, bounds] = useMeasure();

	useEffect(() => {
		if (isError) {
			deleteToken();
		}
	}, [deleteToken, isError]);

	return (
		<StyledQRCode ref={ref}>
			<PorterbuddyLogo />
			<h1>Scan QR code to punch in and out</h1>
			{isLoading && <div>loading</div>}
			{data?.verificationToken && <QRCodeWithData token={data.verificationToken} screenHeight={bounds.height} />}
		</StyledQRCode>
	);
};

const QRCodeWithData: FC<{ token: string; screenHeight: number }> = ({ token, screenHeight }) => {
	const theme = useTheme();
	return (
		<>
			<QRCodeGenerator
				value={`${settings.url}/punch/${token}`}
				size={screenHeight - screenHeight * 0.4}
				bgColor={theme.colors.background.primary}
			/>
			<div style={{ height: 32 }} />
			<TokenExpiresIn token={token} />
		</>
	);
};

const StyledQRCode = styled.div`
	border: 1px solid black;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
