import { RouteComponentProps } from '@reach/router';
import { format, isBefore } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ErrorAlert, SuccessAlert } from '../components/Alerts';
import { Link } from '../components/Link';
import { PunchButton } from '../components/PunchButton';
import { TokenExpiresIn } from '../components/TokenExpiresIn';
import { PunchInOut, useIsPunchedIn, usePreviousPunch, usePunchInOut } from '../network/usePunchClient';
import safeJwtDecode from '../utils/safeJwtDecode';

type PunchInOutState =
	| { type: 'init' }
	| { type: 'loading' }
	| { type: 'success'; inOut: 'IN' | 'OUT' }
	| { type: 'error'; errorMessage: string };

export const PunchClock: FC<RouteComponentProps<{ token: string }>> = ({ token }) => {
	if (!token) {
		return <PunchClockWrapper>Nothing here</PunchClockWrapper>;
	}

	return <PunchClockWithToken token={token} />;
};

const PunchClockWithToken: FC<{ token: string }> = ({ token }) => {
	const punchedIn = useIsPunchedIn(token);
	const previousPunch = usePreviousPunch(token);

	return (
		<PunchClockWithData
			token={token}
			lastPunch={previousPunch.data}
			punchedIn={Boolean(punchedIn.data)}
			checkIfUserIsPunchedIn={async () => {
				await punchedIn.refetch();
			}}
		/>
	);
};

const PunchClockWithData: FC<{
	token: string;
	lastPunch?: PunchInOut;
	punchedIn: boolean;
	checkIfUserIsPunchedIn: () => Promise<void>;
}> = ({ token, lastPunch, punchedIn, checkIfUserIsPunchedIn }) => {
	const punchInOut = usePunchInOut(token);
	const [state, setState] = useState<PunchInOutState>({ type: 'init' });
	const [punched, setPunched] = useState<boolean>(false);

	const decodedToken = safeJwtDecode<{ exp: number }>(token);

	useEffect(() => {
		let timer: NodeJS.Timer | null;

		if (state.type === 'error') {
			setTimeout(() => {
				setState({ type: 'init' });
			}, 5000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [state]);

	const handlePunchInOut = async () => {
		setState({ type: 'loading' });

		const punchInOutResponse = await punchInOut(punchedIn ? 'OUT' : 'IN');

		if (punchInOutResponse?.success) {
			await checkIfUserIsPunchedIn();
			setState({ type: 'success', inOut: punchedIn ? 'OUT' : 'IN' });
			setPunched(true);
		} else {
			setState({ type: 'error', errorMessage: punchInOutResponse?.error || 'Something wrong happened' });
		}
	};

	return (
		<PunchClockWrapper>
			{isBefore(new Date((decodedToken?.exp ?? 0) * 1000), new Date()) ? (
				<>
					<Link style={{ marginBottom: 8 }} to="/">
						Go to home page
					</Link>
					<ErrorAlert>The QR code has expired, scan the QR code again</ErrorAlert>
				</>
			) : (
				<>
					Previous shift{' '}
					{lastPunch
						? `${format(new Date(lastPunch.clockIn), 'dd.MM HH:mm')} - ${
								lastPunch.clockOut ? format(new Date(lastPunch.clockOut), 'dd.MM HH:mm') : 'N/A'
						  }`
						: 'N/A'}
					{punched ? (
						<Link style={{ marginTop: 8 }} to="/">
							Go to home page
						</Link>
					) : (
						<>
							<h2>Punch {punchedIn ? 'out' : 'in'}</h2>
							<PunchButton onClick={handlePunchInOut} />
							<TokenExpiresIn token={token} />
						</>
					)}
					{(() => {
						switch (state.type) {
							case 'init':
								return null;
							case 'loading':
								return <div>Loading...</div>;
							case 'success':
								return (
									<SuccessAlert style={{ marginTop: 16 }}>
										You have successfully punched {punchedIn ? 'in' : 'out'}!
									</SuccessAlert>
								);
							case 'error':
								return <ErrorAlert style={{ marginTop: 16 }}>{state.errorMessage}</ErrorAlert>;
						}
					})()}
				</>
			)}
		</PunchClockWrapper>
	);
};

const PunchClockWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 90vh;
`;
