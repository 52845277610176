import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import authClient, { AuthHeadersType } from '../network/authClient';
import { settings } from '../settings';
import { porterApi } from '../utils/api';
import storage from '../utils/storage';

export const CLIENT_AUTH_HEADERS = '@PorterbuddyStore:CLIENT_AUTH_HEADERS';

type Role = 'ADMIN' | 'SENDER' | 'SENDER_ADMIN' | 'COURIER' | 'COURIER_ADMIN';

export type UserType = {
	email: string;
	role: Role;
	firstName: string;
	lastName: string;
};

type AuthState =
	| {
			type: 'signedOut';
	  }
	| {
			type: 'signedIn';
			user: UserType;
			authHeaders: AuthHeadersType;
	  }
	| {
			type: 'loading';
	  }
	| {
			type: 'error';
			error: string;
	  };

type UseAuthType = {
	state: AuthState;
	signIn: (email: string, password: string) => Promise<void>;
	signOut: () => Promise<void>;
};

const useClientAuth = (): UseAuthType => {
	const [state, setState] = useState<AuthState>({
		type: 'signedOut',
	});

	useEffect(() => {
		(async () => {
			const authHeaders = storage.getData<AuthHeadersType | null>(CLIENT_AUTH_HEADERS, null);
			setState({
				type: 'loading',
			});
			if (authHeaders) {
				try {
					const userAccessResponse = await porterApi(authHeaders?.Authorization).get<UserType>('/user/access');
					const userMeResponse = await porterApi(authHeaders?.Authorization).get<UserType>('/user/me');

					if (userAccessResponse.data.role !== 'COURIER') {
						setState({
							type: 'error',
							error: 'You are trying to login with an unsupported account type, please login as a courier',
						});

						void storage.deleteData(CLIENT_AUTH_HEADERS);
						await authClient.signOut();

						if (settings.debugMode) {
							console.log('Signed out due to wrong credentials');
						}
						return;
					}

					setState({
						type: 'signedIn',
						authHeaders,
						user: {
							...userMeResponse.data,
							...userAccessResponse.data,
						},
					});
				} catch (_err) {
					const err = _err as AxiosError;
					if ([401, 403].includes(err.response?.status ?? -1)) {
						setState({
							type: 'error',
							error: 'Could not sign in. Wrong username or password',
						});
					}
				}
			} else {
				setState({ type: 'signedOut' });
			}
		})().catch(err => {
			console.error('Error checking login status: ', err);
			setState({
				type: 'error',
				error: 'Something went wrong',
			});
			// Add logging. For example sentry
		});
	}, []);

	const signIn = useCallback(async (email: string, password: string): Promise<void> => {
		setState({
			type: 'loading',
		});
		const signInResult = await authClient.signIn(email, password);
		if (signInResult.success) {
			const newAuthHeaders = signInResult.data?.authHeaders;
			void storage.storeData(CLIENT_AUTH_HEADERS, newAuthHeaders);

			const userAccessResponse = await porterApi(newAuthHeaders?.Authorization).get<UserType>('/user/access');
			const userMeResponse = await porterApi(newAuthHeaders?.Authorization).get<UserType>('/user/me');

			console.log(`userAccessResponse`, userAccessResponse);
			console.log(`userMeResponse`, userMeResponse);

			if (userAccessResponse.data.role !== 'COURIER') {
				setState({
					type: 'error',
					error: 'You are trying to login with an unsupported account type, please login as a courier',
				});

				void storage.deleteData(CLIENT_AUTH_HEADERS);
				await authClient.signOut();

				if (settings.debugMode) {
					console.log('Signed out due to wrong credentials');
				}
				return;
			}

			if (newAuthHeaders) {
				setState({
					type: 'signedIn',
					authHeaders: newAuthHeaders,
					user: {
						...userMeResponse.data,
						...userAccessResponse.data,
					},
				});
			}
		} else {
			setState({
				type: 'error',
				error: signInResult.errorMessage ?? 'Something went wrong',
			});
		}
	}, []);

	const signOut = useCallback(async (): Promise<void> => {
		try {
			setState({
				type: 'signedOut',
			});
			void storage.deleteData(CLIENT_AUTH_HEADERS);
			await authClient.signOut();
			if (settings.debugMode) {
				console.log('Signed out from Firebase');
			}
		} catch (e) {
			if (settings.debugMode) {
				console.log('Error signing out from Firebase', e);
			}
			// Add logging. For example sentry
		}
	}, []);

	return {
		state,
		signIn,
		signOut,
	};
};

const ClientAuthContainer = createContainer(useClientAuth);
export const ClientAuthProvider = ClientAuthContainer.Provider;
export const useClientAuthStore = (): UseAuthType => ClientAuthContainer.useContainer();
