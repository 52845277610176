import { RouteComponentProps } from '@reach/router';
import { differenceInMinutes, format } from 'date-fns';
import { darken } from 'polished';
import { FC } from 'react';
import styled from 'styled-components';
import { PunchInOut, usePunchHistory } from '../network/usePunchClient';
import { useClientAuthStore } from '../store/clientAuth';
import { minutesToHoursAndMinutes } from '../utils/time';

export const Home: FC<RouteComponentProps> = () => {
	const { state, signOut } = useClientAuthStore();
	const { data, isLoading } = usePunchHistory();

	return (
		<StyledHome>
			<p>Signed in as {state.type === 'signedIn' && state.user.email}</p>
			<p>Scan QR code at the hub to punch in or out</p>
			{isLoading && <div>Loading punch history...</div>}
			{data && <History history={data} />}
			<button onClick={signOut}>Sign out</button>
		</StyledHome>
	);
};

const History: FC<{ history: PunchInOut[] }> = ({ history }) => (
	<StyledHistory>
		<h3>Work history</h3>
		<div>
			<table>
				<thead>
					<tr>
						<th>Week day</th>
						<th>In</th>
						<th>Out</th>
						<th>Work time</th>
					</tr>
				</thead>
				<tbody>
					{history.map(punch => (
						<tr key={punch.clockIn}>
							<td>{format(new Date(punch.clockIn), 'EEEE do MMMM')}</td>
							<td>{format(new Date(punch.clockIn), 'HH:mm')}</td>
							<td>{punch.clockOut ? format(new Date(punch.clockOut), 'HH:mm') : 'N/A'}</td>
							<td>
								{punch.clockOut
									? minutesToHoursAndMinutes(differenceInMinutes(new Date(punch.clockOut), new Date(punch.clockIn)))
									: 'N/A'}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	</StyledHistory>
);

const StyledHistory = styled.div`
	margin: 0 16px 32px 16px;

	div {
		max-height: 300px;
		overflow-y: auto;

		table {
			tbody,
			thead {
				tr {
					td,
					th {
						text-align: left;
						padding-right: 16px;

						&:last-child {
							text-align: right;
							padding-right: 0;
						}
					}
				}
			}
		}
	}
`;

const StyledHome = styled.div`
	width: 100%;
	height: 70vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		font-size: 1.2rem;
		font-weight: bold;
		margin-top: 8px;
		margin-bottom: 8px;
		color: ${({ theme }) => theme.colors.porter.primary};
		border: 1px solid ${({ theme }) => theme.colors.porter.primary};
		padding: 8px;
		border-radius: 8px;
		cursor: pointer;

		&:hover {
			background-color: ${({ theme }) => darken(0.1, theme.colors.background.secondary)};
		}

		&:active {
			background-color: ${({ theme }) => darken(0.2, theme.colors.background.secondary)};
		}
	}
`;
