import axios from 'axios';
import { settings } from '../settings';
import { TERMINAL_VERIFICATION_TOKEN } from '../store/verificationToken';
import storage from './storage';

export const porterApi = (token?: string) => {
	return axios.create({
		baseURL: settings.apiUrl,
		headers: {
			authorization: token,
		},
	});
};

export const invoicingApi = (tokens?: { token?: string; verificationToken?: string }) => {
	const storageVerificationToken = storage.getData<string>(TERMINAL_VERIFICATION_TOKEN);
	const terminalToken = tokens?.verificationToken || storageVerificationToken;

	return axios.create({
		baseURL: settings.invoicingApiUrl,
		headers: terminalToken
			? {
					authorization: tokens?.token,
					terminalAuthorization: terminalToken,
			  }
			: {
					authorization: tokens?.token,
			  },
	});
};
