import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTerminalVerificationTokenStore } from '../store/verificationToken';

export const PorterbuddyLogo: FC<{ fill?: string }> = ({ fill = '#661aff' }) => {
	const { deleteToken } = useTerminalVerificationTokenStore();
	const [logoPressCount, setLogoPressCount] = useState<number>(0);

	const handleLogoPress = () => {
		if (logoPressCount === 6) {
			deleteToken();
		} else {
			setLogoPressCount(cs => cs + 1);
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timer | null;

		if (logoPressCount > 0) {
			setTimeout(() => {
				setLogoPressCount(0);
			}, 10000);
		}
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [logoPressCount]);

	return (
		<PorterbuddyLogoSvg
			viewBox="0 0 179 27"
			version="1.1"
			style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
			onClick={handleLogoPress}
		>
			<g transform="matrix(1,0,0,1,-19.3,-14.9)">
				<g>
					<path
						d="M44.4,18.3C39.4,18.3 36,22.2 36,28.2C36,34.2 39.3,38.1 44.4,38.1C49.5,38.1 52.8,34.2 52.8,28.2C52.8,22.2 49.5,18.3 44.4,18.3ZM44.4,34.5C41.7,34.5 39.8,32.1 39.8,28.2C39.8,24.3 41.7,21.9 44.4,21.9C47.1,21.9 49,24.3 49,28.2C49,32.1 47.2,34.5 44.4,34.5Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M72.7,34.2C71.9,34.2 71.3,33.6 71.3,32.7L71.3,22.2L75.2,22.2L75.2,18.7L71.3,18.7L71.3,14.9L67.5,16.7L67.5,18.7L62.7,18.7C60.9,18.7 59.5,19.9 59,21.7L58.7,18.7L55.4,18.7L55.4,37.8L59.2,37.8L59.2,27.8C59.2,24.4 60.5,22.2 62.9,22.2L67.5,22.2L67.5,33.3C67.5,36 69.2,37.8 71.7,37.8L75.2,37.8L75.6,34.3L72.7,34.2Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M85.8,34.5C82.8,34.5 81,32.1 80.8,28.9L93.3,28.9C94.2,22.9 90.9,18.3 85.5,18.3C80.4,18.3 77.1,22.7 77.1,28.2C77.1,33.8 80.6,38.1 85.9,38.1C89.8,38.1 93.1,35.8 93.8,31.8L90.2,31.1C89.7,33.2 88,34.5 85.8,34.5ZM85.7,21.9C87.8,21.9 89.4,23.4 89.6,25.6L81.1,25.6C81.8,23.2 83.5,21.9 85.7,21.9Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M99.9,21.7L99.6,18.7L96.3,18.7L96.3,37.8L100.1,37.8L100.1,27.8C100.1,24.4 101.3,22.2 103.8,22.2L105.6,22.2L105.6,18.7L103.5,18.7C101.8,18.6 100.5,19.8 99.9,21.7Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M116.7,18.3C114.6,18.3 112.7,19.5 111.5,21.4L111.5,14.9L107.7,14.9L107.7,37.8L111,37.8L111.3,34.8C112.4,36.9 114.4,38.2 116.6,38.2C121.1,38.2 124.4,34.3 124.4,28.3C124.5,22.2 121.3,18.3 116.7,18.3ZM116.1,34.5C113.5,34.5 111.6,32 111.6,28.2C111.6,24.4 113.4,21.9 116.1,21.9C118.9,21.9 120.8,24.3 120.8,28.2C120.7,32.1 118.8,34.5 116.1,34.5Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M138.3,29.5C138.3,32.3 136.8,34.5 134.3,34.5C132,34.5 130.5,32.9 130.5,30.3L130.5,18.6L126.7,18.6L126.7,31C126.7,35.4 129.3,38.1 133.2,38.1C135.6,38.1 137.5,36.7 138.5,34.4L138.8,37.7L142.1,37.7L142.1,18.6L138.3,18.6L138.3,29.5Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M157.1,21.4C156,19.4 154,18.3 151.9,18.3C147.4,18.3 144.1,22.2 144.1,28.2C144.1,34.2 147.4,38.1 151.9,38.1C154.2,38.1 156.2,36.8 157.3,34.6L157.6,37.7L160.9,37.7L160.9,14.9L157.1,14.9L157.1,21.4ZM152.6,34.5C149.9,34.5 148,32.1 148,28.2C148,24.3 149.8,21.9 152.6,21.9C155.3,21.9 157.1,24.4 157.1,28.2C157.1,32.1 155.3,34.5 152.6,34.5Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M176.2,21.4C175.1,19.4 173.1,18.3 171,18.3C166.5,18.3 163.2,22.2 163.2,28.2C163.2,34.2 166.5,38.1 171,38.1C173.3,38.1 175.3,36.8 176.4,34.6L176.7,37.7L180,37.7L180,14.9L176.2,14.9L176.2,21.4ZM171.7,34.5C169,34.5 167.1,32.1 167.1,28.2C167.1,24.3 168.9,21.9 171.7,21.9C174.4,21.9 176.2,24.4 176.2,28.2C176.2,32.1 174.3,34.5 171.7,34.5Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M194,18.6L190.1,30.1L186.6,18.6L182.5,18.6L188,35C188,35 188.3,37.7 184.9,37.7L184.8,41.2C189.3,41.2 190,40.6 191.4,36.7L198,18.6L194,18.6Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
					<path
						d="M26,14.9L19.3,14.9L19.3,37.7L23.2,37.7L23.2,30.3L26.4,30.3C32.1,30.3 34.5,27.4 34.5,22.6C34.5,17.8 31.8,14.9 26,14.9ZM26,26.6L23.1,26.6L23.1,18.6L26,18.6C29.4,18.6 30.8,20.1 30.8,22.6C30.8,25.2 29.4,26.6 26,26.6Z"
						style={{ fill, fillRule: 'nonzero' }}
					/>
				</g>
			</g>
		</PorterbuddyLogoSvg>
	);
};

const PorterbuddyLogoSvg = styled.svg`
	width: 100px;
	position: absolute;
	top: 16px;
	left: 16px;
`;
