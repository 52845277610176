import { settings } from '../settings';
import { porterApi } from '../utils/api';
import { auth } from '../utils/firebase';

type SignInResponse = {
	success: boolean;
	data: {
		user: unknown;
		authHeaders: AuthHeadersType;
	} | null;
	errorMessage: string | null;
};

export type AuthHeadersType = { Authorization: string; uid: string };

async function sessionLogin(idToken: string): Promise<string | null> {
	const body = { idToken: idToken };

	try {
		const response = await porterApi(undefined).post<{ token: string }>('/auth/session-login', body);

		if (response.status !== 200) {
			return null;
		}

		return response.data.token;
	} catch (err) {
		console.log(`Session login request failed: ${JSON.stringify(err)}`);
		return null;
	}
}

async function signIn(username: string, password: string): Promise<SignInResponse> {
	let userCredential;

	try {
		userCredential = await auth().signInWithEmailAndPassword(username, password);
	} catch (err: any) {
		if (settings.debugMode) {
			console.error('Firebase sign in error', err);
		}

		const errCode = err && err.code;
		const errMsg = (err && err.message) || '';
		if (errCode === 'auth/wrong-password') {
			console.warn(`Firebase sign in failed for user '${username}', wrong password: ${errMsg}`);
		} else if (errCode === 'auth/invalid-email') {
			console.warn(`Firebase sign in failed for user '${username}', invalid email: ${errMsg}`);
		} else if (errCode === 'auth/user-disabled') {
			console.warn(`Firebase sign in failed for user '${username}', user disabled in Firebase: ${errMsg}`);
		} else {
			console.error(`Firebase sign in failed for user '${username}': ${err}`);
		}

		return {
			success: false,
			data: null,
			errorMessage: errMsg || 'Wrong username or password',
		};
	}

	if (!userCredential.user) {
		return {
			success: false,
			data: null,
			errorMessage: 'Error getting user',
		};
	}

	if (settings.debugMode) {
		console.log('User signed in with Firebase', userCredential);
	}
	const token = await userCredential.user.getIdToken();

	const sessionToken = await sessionLogin(token);

	if (!sessionToken) {
		return {
			success: false,
			data: null,
			errorMessage: 'An error occurred during login. Please contact Porterbuddy if the problem continues.',
		};
	}

	const authHeaders: AuthHeadersType = {
		Authorization: `Bearer ${sessionToken}`,
		uid: username,
	};

	return {
		success: true,
		data: {
			authHeaders,
			user: userCredential.user,
		},
		errorMessage: null,
	};
}

async function signOut(): Promise<void> {
	try {
		await auth().signOut();
		if (settings.debugMode) {
			console.log('Signed out from Firebase');
		}
	} catch (e) {
		if (settings.debugMode) {
			console.log('Error signing out from Firebase', e);
		}
	}
}

const authClient = {
	signIn,
	signOut,
};

export default authClient;
