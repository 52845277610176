import { isAfter } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';
import storage from '../utils/storage';

export const TERMINAL_VERIFICATION_TOKEN = '@PorterbuddyStore:TERMINAL_VERIFICATION_TOKEN';

type DecodedJWT = {
	aud: string;
	exp: number;
	iss: string;
	region: string;
	sub: string;
};

const getTerminalVerificationToken = (): string | null => {
	const terminalVerificationToken = storage.getData<string>(TERMINAL_VERIFICATION_TOKEN);

	return terminalVerificationToken;
};

export const useTerminalVerificationToken = () => {
	const [terminalVerificationToken, setTerminalVerificationToken] = useState<string | null>(
		getTerminalVerificationToken
	);

	const handleDeleteToken = useCallback(() => {
		setTerminalVerificationToken(null);
		storage.deleteData(TERMINAL_VERIFICATION_TOKEN);
	}, []);

	const handleSetToken = useCallback((token: string) => {
		const success = storage.storeData(TERMINAL_VERIFICATION_TOKEN, token);
		decodeJWTToken(token);

		if (success) {
			setTerminalVerificationToken(token);
		}
	}, []);

	const decodeJWTToken = (jwt: string) => {
		var decoded = jwtDecode<DecodedJWT>(jwt);

		return decoded;
	};

	const terminalVerified = () => {
		if (terminalVerificationToken) {
			const decodedToken = decodeJWTToken(terminalVerificationToken);

			const tokenExpiresAt = decodedToken.exp * 1000;

			return isAfter(new Date(tokenExpiresAt), new Date());
		}

		return false;
	};

	return {
		decodedToken: terminalVerificationToken && decodeJWTToken(terminalVerificationToken),
		token: terminalVerificationToken,
		terminalVerified: terminalVerified(),
		setToken: handleSetToken,
		deleteToken: handleDeleteToken,
	};
};

const TerminalVerificationTokenContainer = createContainer(useTerminalVerificationToken);
export const TerminalVerificationTokenProvider = TerminalVerificationTokenContainer.Provider;
export const useTerminalVerificationTokenStore = () => TerminalVerificationTokenContainer.useContainer();
