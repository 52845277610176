import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';
import authClient from '../network/authClient';
import { settings } from '../settings';
import { invoicingApi } from '../utils/api';
import { useTerminalVerificationTokenStore } from './verificationToken';

type AuthState =
	| {
			type: 'signedOut';
	  }
	| {
			type: 'loading';
	  }
	| {
			type: 'error';
			error: string;
	  };

type UseAuthType = {
	state: AuthState;
	signIn: (email: string, password: string, region: string) => Promise<void>;
};

const useAuth = (): UseAuthType => {
	const { setToken } = useTerminalVerificationTokenStore();
	const [state, setState] = useState<AuthState>({
		type: 'signedOut',
	});

	const signIn = useCallback(
		async (email: string, password: string, region: string): Promise<void> => {
			setState({
				type: 'loading',
			});
			const signInResult = await authClient.signIn(email, password);
			if (signInResult.success) {
				const verificationTokenResponse = await invoicingApi({
					token: signInResult.data?.authHeaders.Authorization,
				}).get<{
					verificationToken: string;
				}>('time-tracking/validate-client', {
					params: {
						region,
					},
				});

				void setToken(verificationTokenResponse.data.verificationToken);

				try {
					await authClient.signOut();

					if (settings.debugMode) {
						console.log('Signed out from Firebase');
					}

					setState({
						type: 'signedOut',
					});
				} catch (e) {
					if (settings.debugMode) {
						console.log('Error signing out from Firebase', e);
					}
					// Add logging. For example sentry
				}
			} else {
				setState({
					type: 'error',
					error: signInResult.errorMessage ?? 'Something went wrong',
				});
			}
		},
		[setToken]
	);

	return {
		state,
		signIn,
	};
};

const AuthContainer = createContainer(useAuth);
export const AuthProvider = AuthContainer.Provider;
export const useAuthStore = (): UseAuthType => AuthContainer.useContainer();
