type Options = {
	debugMode: boolean;
	url: string;
	apiUrl: string;
	invoicingApiUrl: string;
	firebaseKey: string;
	environment: string,
}

const localOptions: Options = {
	debugMode: process.env.NODE_ENV === 'development',
	url: process.env.REACT_APP_URL || 'https://punch-clock.porterbuddy.com/',
	apiUrl: process.env.REACT_APP_API_URL!,
	invoicingApiUrl: process.env.REACT_APP_INVOICING_API_URL!,
	firebaseKey: process.env.REACT_APP_FIREBASE_KEY!,
	environment: 'local'
}

const testOptions: Options = {
	debugMode: false,
	url: 'https://punch-clock.porterbuddy-test.com',
	apiUrl: 'https://api.porterbuddy-test.com',
	invoicingApiUrl: 'https://invoicing-api.porterbuddy-test.com',
	firebaseKey: 'AIzaSyCBomPE274DRpnTKIUeK7XVn2DA1aiShQY',
	environment: 'test'
}

const prodOptions: Options = {
	debugMode: false,
	url: 'https://punch-clock.porterbuddy.com',
	apiUrl: 'https://api.porterbuddy.com',
	invoicingApiUrl: 'https://invoicing-api.porterbuddy.com',
	firebaseKey: 'AIzaSyC0LWVYAzBK2uwCwFdGQNHzgy7kFYUvFdY',
	environment: 'production'
}

let options = prodOptions;

const host = window.location.host;

if (
	host.includes('porterbuddy-test.com') ||
	host.includes('pb-punch-clock-staging')
) {
	options = testOptions;
}

if (host.includes('localhost')) {
	options = localOptions;
}

console.log(`Environment: ${options.environment}`);

export const settings = {
	...options
};