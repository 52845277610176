import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { dark, light, ThemeName } from '../styles/themes';

const THEME_STORAGE_KEY: string = 'SELECTED_THEME';

const getCurrentTheme = (): ThemeName => {
	const currentTheme = localStorage.getItem(THEME_STORAGE_KEY);
	return currentTheme === 'dark' ? 'dark' : 'light';
};

export const useTheme = () => {
	const [currentTheme, setCurrentTheme] = useState<ThemeName>(getCurrentTheme);

	return {
		theme: currentTheme === 'light' ? light : dark,
		currentTheme,
		changeTheme: (theme?: ThemeName) => {
			setCurrentTheme(ct => (theme ? theme : ct === 'light' ? 'dark' : 'light'));
		},
	};
};

const ThemeContainer = createContainer(useTheme);
export const ThemeProvider = ThemeContainer.Provider;
export const useThemeStore = () => ThemeContainer.useContainer();
