import { differenceInSeconds } from 'date-fns';
import { FC, useState } from 'react';
import safeJwtDecode from '../utils/safeJwtDecode';
import { convertToDuration } from '../utils/time';
import useInterval from '../utils/useTimeout';

export const TokenExpiresIn: FC<{ token: string }> = ({ token }) => {
	const decodedToken = safeJwtDecode<{ exp: number }>(token);

	return decodedToken ? <TokenExpiresInWithData expires={decodedToken.exp} /> : <div>Can't read token</div>;
};

const TokenExpiresInWithData: FC<{ expires: number }> = ({ expires }) => {
	const [secondsUntilExpiry, setSecondsUntilExpiry] = useState<number>(
		differenceInSeconds(new Date(expires * 1000), new Date())
	);

	useInterval(() => {
		setSecondsUntilExpiry(differenceInSeconds(new Date(expires * 1000), new Date()));
	}, 1000);

	return <div>{convertToDuration(secondsUntilExpiry)}</div>;
};
