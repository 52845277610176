import { FC, useEffect, useRef, useState } from 'react';
import { ErrorAlert } from '../components/Alerts';
import { InteractiveBackground } from '../components/InteractiveBackground';
import { useAuthStore } from '../store/adminAuth';
import { SignInContainer } from '../components/SignInContainer';

export const SignIn: FC = () => {
	const { state, signIn } = useAuthStore();

	const passwordRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [region, setRegion] = useState<string>('');

	useEffect(() => {
		emailRef?.current?.focus();
	}, []);

	return (
		<InteractiveBackground
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<SignInContainer
				onSubmit={e => {
					e.preventDefault();
					signIn(email, password, region);
				}}
			>
				<h2>Verify this terminal</h2>
				<p>Sign in with an admin account to verify this terminal</p>
				<select value={region} onChange={event => setRegion(event.target.value)}>
					<option value="" disabled>
						Choose region
					</option>
					<option value="OSLO">Oslo</option>
					<option value="BERGEN">Bergen</option>
					<option value="GRENLAND">Grenland</option>
					<option value="ØSTFOLD">Østfold</option>
					<option value="STAVANGER">Stavanger</option>
					<option value="TRONDHEIM">Trondheim</option>
					<option value="VESTFOLD">Vestfold</option>
				</select>
				<input
					placeholder="E-mail"
					type="text"
					value={email}
					ref={emailRef}
					onChange={event => setEmail(event.target.value)}
					onKeyPress={e => {
						if (e.key === 'enter') {
							if (!password) {
								passwordRef.current?.focus();
							} else {
								signIn(email, password, region);
							}
						}
					}}
					autoComplete="username"
				/>
				<input
					placeholder="Password"
					type="password"
					value={password}
					ref={passwordRef}
					onChange={event => setPassword(event.target.value)}
					autoComplete="current-password"
				/>
				{state.type === 'error' && <ErrorAlert>{state.error}</ErrorAlert>}
				<button disabled={!region || !email || !password}>Verify</button>
			</SignInContainer>
		</InteractiveBackground>
	);
};
