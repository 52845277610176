import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider as StyledThemProvider } from 'styled-components';
import { InteractiveBackground } from './components/InteractiveBackground';
import { Loader } from './components/Loader';
import { PorterbuddyLogo } from './components/PorterbuddyLogo';
import { Home } from './pages/Home';
import { ClientSignIn } from './pages/ClientSignIn';
import { PunchClock } from './pages/PunchClock';
import { QRCode } from './pages/QRCode';
import { SignIn } from './pages/SignIn';
import { AuthProvider, useAuthStore } from './store/adminAuth';
import { ClientAuthProvider, useClientAuthStore } from './store/clientAuth';
import { ThemeProvider, useThemeStore } from './store/theme';
import { TerminalVerificationTokenProvider, useTerminalVerificationTokenStore } from './store/verificationToken';
import { GlobalStyle } from './styles/style';
import { RouteComponentProps, Router } from '@gatsbyjs/reach-router';

export const App: FC = () => {
	return (
		<TerminalVerificationTokenProvider>
			<AuthProvider>
				<ClientAuthProvider>
					<ThemeProvider>
						<InnerApp />
					</ThemeProvider>
				</ClientAuthProvider>
			</AuthProvider>
		</TerminalVerificationTokenProvider>
	);
};

const queryClient = new QueryClient();

const InnerApp: FC = () => {
	const { theme } = useThemeStore();

	return (
		<QueryClientProvider client={queryClient}>
			<StyledThemProvider theme={theme}>
				<GlobalStyle />
				<Router>
					<Terminal path="/terminal" />
					<Client path="/*" />
				</Router>
			</StyledThemProvider>
		</QueryClientProvider>
	);
};

const Client: FC<RouteComponentProps> = () => {
	const { state } = useClientAuthStore();

	switch (state.type) {
		case 'loading':
			return (
				<>
					<PorterbuddyLogo fill="white" />
					<InteractiveBackground style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Loader />
					</InteractiveBackground>
				</>
			);

		case 'signedOut':
		case 'error':
			return (
				<>
					<PorterbuddyLogo fill="white" />
					<ClientSignIn />
				</>
			);

		case 'signedIn':
			return (
				<>
					<PorterbuddyLogo />
					<Router>
						<Home path="/" />
						<PunchClock path="/punch/:token" />
					</Router>
				</>
			);
	}
};

const Terminal: FC<RouteComponentProps> = () => {
	const { terminalVerified } = useTerminalVerificationTokenStore();

	return terminalVerified ? <QRCode /> : <AdminSignInApp />;
};

const AdminSignInApp: FC = () => {
	const { state } = useAuthStore();

	switch (state.type) {
		case 'loading':
			return (
				<>
					<PorterbuddyLogo fill="white" />
					<InteractiveBackground style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Loader />
					</InteractiveBackground>
				</>
			);

		case 'signedOut':
		case 'error':
			return (
				<>
					<PorterbuddyLogo fill="white" />
					<SignIn />
				</>
			);
	}
};
