import styled from 'styled-components';
import punchReady from '../assets/punchReady.svg';
import punchPushed from '../assets/punchPushed.svg';

export const PunchButton = styled.button`
	margin-bottom: 32px;
	width: 170.2px;
	height: 170.2px;
	background: url(${punchReady});
	background-size: 100% auto;

	&:active {
		background: url(${punchPushed});
		background-size: 100% auto;
	}
`;
