import styled from 'styled-components';

export const SignInContainer = styled.form`
	display: flex;
	flex-direction: column;
	width: 300px;
	background-color: ${({ theme }) => theme.colors.background.secondary};
	padding: 32px;
	border-radius: 16px;

	h2 {
		margin: 0 0 16px 0;
	}

	select {
		margin-bottom: 8px;
		font-size: 1rem;
		border: 1px solid ${({ theme }) => theme.colors.border};
		padding: 8px;
		border-radius: 8px;
	}

	input {
		font-size: 1rem;
		border: 1px solid ${({ theme }) => theme.colors.border};
		padding: 8px;
		border-radius: 8px;
		margin-bottom: 8px;
	}

	button {
		font-size: 1rem;
		margin-top: 8px;
		border: 1px solid ${({ theme }) => theme?.colors?.border};
		padding: 8px;
		border-radius: 8px;
	}
`;
