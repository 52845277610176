export function convertToDuration(secondsAmount: number): string {
	if (secondsAmount < 0) {
		return 'The QR code has expired, scan the QR code again';
	}
	const minutes = Math.floor(secondsAmount / 60);
	const seconds = secondsAmount % 60;

	return `QR code expires in ${minutes ? `${minutes} minute${minutes === 1 ? '' : 's'} and` : ''} ${seconds} second${
		seconds === 1 ? '' : 's'
	}`;
}

export function minutesToHoursAndMinutes(minutesAmount: number): string {
	if (minutesAmount < 0) {
		return '0';
	}

	const hours = Math.floor(minutesAmount / 60);
	const minutes = minutesAmount % 60;

	return `${hours ? `${hours} hour${hours === 1 ? '' : 's'}` : ''} ${minutes} minute${minutes === 1 ? '' : 's'}`;
}
