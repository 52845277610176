import jwtDecode from 'jwt-decode';

export default function safeJwtDecode<T>(token?: string): T | undefined {
	if (!token) {
		return undefined;
	}

	try {
		return jwtDecode(token);
	} catch {
		return undefined;
	}
}
