import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

export const GlobalStyle = createGlobalStyle`
	${normalize()};

	html {
		box-sizing: border-box;
	};

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	body {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
			'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 17px;
		margin: 0;
		padding: 0;
		line-height: 1.3;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: ${props => props.theme.colors.background.primary};
		color: ${props => props.theme.colors.text.primary};
	}

	button {
		font: inherit;
		color: inherit;
		background: none;
		cursor: pointer;
		border: none;
		padding: 0;
	}

	button:disabled {
		cursor: default;
	}

	a {
		margin: 0;
		text-decoration: none;
		color: inherit;
	}

	#root {
		width: 100%;
		height: 100%;
	}
`;
