import { Link as ReachRouterLink } from '@gatsbyjs/reach-router';
import styled from 'styled-components';

export const Link = styled(ReachRouterLink)`
	color: ${({ theme }) => theme.colors.porter.primary};

	&:hover {
		text-decoration: underline;
	}
`;
