import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import safeJwtDecode from '../utils/safeJwtDecode';
import { useInvoicingApi } from '../utils/useApi';

export function usePunchInOut(verificationToken: string) {
	const invoicingApi = useInvoicingApi(verificationToken);

	const punchInOut = async (inOut: 'IN' | 'OUT') => {
		try {
			await invoicingApi.post(`time-tracking/${inOut === 'IN' ? 'clock-in' : 'clock-out'}`);

			return {
				success: true,
				error: null,
			};
		} catch (e: unknown) {
			const error = e as AxiosError;
			return {
				success: false,
				error: error.response?.data?.failureReason ?? error.message,
			};
		}
	};

	return punchInOut;
}

export function useIsPunchedIn(token: string) {
	const invoicingApi = useInvoicingApi(token);

	return useQuery<boolean, AxiosError>('is-clocked-in', () =>
		invoicingApi.get('time-tracking/is-clocked-in').then(res => res.data)
	);
}

export type PunchInOut = {
	clockIn: string;
	clockOut: string;
};

export function usePunchHistory() {
	const invoicingApi = useInvoicingApi();

	return useQuery<PunchInOut[], AxiosError>('punch-history', () =>
		invoicingApi.get('time-tracking/clock-history').then(res => res.data)
	);
}

export function usePreviousPunch(token: string) {
	const invoicingApi = useInvoicingApi(token);

	return useQuery<PunchInOut, AxiosError>('previous-clock-activity', () =>
		invoicingApi.get('time-tracking/previous-clock-activity').then(res => res.data)
	);
}

export function useGetQrToken(token: string) {
	const invoicingApi = useInvoicingApi(token);
	const decodedToken = safeJwtDecode<{ region: string }>(token);

	return useQuery<{ verificationToken: string }, AxiosError>(
		'time-tracking-token',
		() =>
			invoicingApi
				.get('time-tracking/time-tracking-token', {
					params: {
						region: decodedToken?.region,
					},
				})
				.then(res => res.data),
		{
			refetchInterval: 150000, // every 2.5 minutes
			refetchIntervalInBackground: true,
		}
	);
}
