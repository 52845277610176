import styled from 'styled-components';

const Alert = styled.div`
	border: 4px solid ${({ theme }) => theme.colors.border};
	border-radius: 8px;
	padding: 12px;
`;

export const ErrorAlert = styled(Alert)`
	border-color: #f5c2c7;
	background-color: #f8d7db;
	color: #851f29;
`;

export const SuccessAlert = styled(Alert)`
	border-color: #bbdbcc;
	background-color: #d1e7dd;
	color: #0e5232;
`;
