import { useClientAuthStore } from './../store/clientAuth';
import { AxiosInstance } from 'axios';
import { invoicingApi } from './api';

export function useInvoicingApi(verificationToken?: string): AxiosInstance {
	const { state } = useClientAuthStore();

	return invoicingApi({
		token: state.type === 'signedIn' ? state.authHeaders.Authorization : undefined,
		verificationToken,
	});
}
